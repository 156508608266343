@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Regular.ttf');
    src: url('Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url('Roboto-Italic.ttf');
    src: url('Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'Roboto';
    src: url('Roboto-Thin.ttf');
    src: url('Roboto-Thin.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url('Roboto-ThinItalic.ttf');
    src: url('Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
  }
  @font-face {
    font-family: 'Roboto';
    src: url('Roboto-Light.ttf');
    src: url('Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url('Roboto-LightItalic.ttf');
    src: url('Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: 'Roboto';
    src: url('Roboto-Medium.ttf');
    src: url('Roboto-Medium.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url('Roboto-MediumItalic.ttf');
    src: url('Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: 'Roboto';
    src: url('Roboto-Bold.ttf');
    src: url('Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url('Roboto-BoldItalic.ttf');
    src: url('Roboto-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }
  @font-face {
    font-family: 'Roboto';
    src: url('Roboto-Black.ttf');
    src: url('Roboto-Black.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url('Roboto-BlackItalic.ttf');
    src: url('Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
  }