//  Curves Material
// -----------Paper-----------
.paper-root {
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
}

.paper-elevation {
    // box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    //             0px 1px 1px 0px rgb(0 0 0 / 14%),
    //             0px 1px 3px 0px rgb(0 0 0 / 12%);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

// -----------Card-----------
.card-root {
    overflow: hidden;
    padding: 24px;
    border-radius: 30px;
    margin: 0 auto 20px;
    & > div + div {
        margin: 20px 0 0 0;
    }
    & > div {
        & > span {
            display: inline-block;
        }
    }
    .card-title {
        text-shadow: 5px 3px 3px rgba(200, 200, 200, 0.5);
        font-weight: 500;
        font-size: 1.5rem;
    }
}

.card-root + .card-root {
    margin-top: 20px;
}

// -----------Card-----------
.container-root {
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin: 0 auto 20px;
    padding: 0 16px;
}

.container-disable-gutters {
    padding-left: 0;
    padding-right: 0;
}

// -----------Form-----------
.form-flex {
    display: flex;
}

.form-flex-al-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-flex-jc-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-flex-row {
    display: flex;
    justify-content: space-between;
}

// -----------spacing-----------
.spacing-1 {
    & > div + div {
        margin-left: 1rem;
    }
}
.spacing-2 {
    & > div + div {
        margin-left: 2rem;
    }
}
.spacing-3 {
    & > div + div {
        margin-left: 3rem;
    }
}
.spacing-4 {
    & > div + div {
        margin-left: 4rem;
    }
}

// -----------price-----------

.price-span {
    > em {
        font-size: 1.25rem;
    }
}

// -----------color-----------
.third-color-normall {
    color: #d62672;
}

.third-color-light {
    color: #f086a5;
}

// -----------allen Test-----------

.div-box-counts {
    display: flex;
    justify-content: center;
    > span {
        display: inline-block;
        line-height: 3;
    }
}

table thead {
    background: #f7f7fb;
    > tr > th.MuiTableCell-root {
        color: #707070;
    }
}

// -----------view-----------
.view-normal-width {
    max-width: 900px;
}

.title-color {
    &-root {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 74px;
        }
    }
    &-pseudo-primary-bg::after {
        background-color: #601986;
    }
    &-pseudo-secondary-bg::after {
        background-color: #f086a5;
    }
    &-pseudo-auxiliary-1-bg::after {
        background-color: #65dcfb;
    }
    &-pseudo-auxiliary-2-bg::after {
        background-color: #bb8df0;
    }
}

.title-color-label {
    font-size: 1.5rem;
    position: relative;
    color: #fff;
    z-index: 1;
    padding: 0 0 0 0.5rem;
}

.button-root {
    > button + button {
        margin: 0 0 0 2rem;
    }
}

.bread-crumb {
    &-root {
        & span + span {
            &::before {
                content: " \\ ";
                font-size: 1rem;
                color: #c8c8c8;
            }
        }
    }
}

.table-title-shipday {
    font-size: 12px;
    font-weight: 400;
    color: #ababab;
}
