.category {
    padding: 0 0.75rem;
}

.category-searchbox {
    padding: 1rem 1.5rem;
    height: 80px;
    align-items: end;
}

.category-pre {
    margin: 0 1rem 0 0;
}

.category-pre-icon {
    transition: 0.5s;
    &:hover {
        transform: translateX(-5px);
    }
}

.category-box {
    width: 294px;
    height: 200px;
    padding: 1rem 1.5rem;
    display: inline-block;
    margin-right: 0.8rem;
    margin-top: 1rem;
    &:last-of-type {
        margin-right: 0;
    }
}

.category-box:not(:last-child) {
    cursor: pointer;
    transition: 0.5s;
    &:hover {
        transform: translateY(-5px);
    }
}

.category-content {
    padding: 2.5rem;
}

.category-group {
    display: flex;
    gap: 2.5rem;
    height: 120px;
}

.category-portraits {
    align-self: center;
}

.category-portraits-img {
    font-size: 7rem;
}

.category-details {
    align-self: center;
}

.category-details-title {
    line-height: 130%;
}

.category-buttons {
    align-self: center;
    justify-content: end;
}

.category-table-row {
    & > * {
        border-bottom: unset !important;
    }
}

.category-tabs {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.category-tabs-add {
    margin: 0 1rem;
}

.category-check-icon {
    color: #16db0a;
    width: 2rem !important;
    height: 2rem !important;
}

.category-sub-table {
    width: 48%;
    margin: 0 0 1rem auto;
}

.category-plus {
    display: flex;
    align-items: center;
    justify-content: center;
}

.category-plus-icon {
    width: 150px;
    height: 150px;
    margin: 1rem 0 0 0;
    transition: 0.5s;
    &:hover {
        transform: translateY(-5px);
    }
    g > g > path {
        fill: #bb8df0;
    }
}

.category-dialog-title {
    padding: 0 0 0 20px;
}

.category-dialog-button {
    padding: 1.5rem 0 1rem;
}

.category-dialog-delete-content {
    margin-top: 1rem;
    text-align: center;
}

.category-dialog-warn-icon {
    color: #e65159;
    width: 10rem !important;
    height: 10rem !important;
}

.category-dialog-title {
    font-size: $h4-font-size;
    margin: 0.813rem 0 0;
    font-weight: $font-medium;
}
.category-dialog-subtitle {
    font-size: $h6-font-size;
    margin: 0.813rem 0 0;
    color: $text-color-light;
}

.category-dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.category-dialog-context {
    margin: 0.813rem 0 0 0;
}
