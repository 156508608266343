.login {
    &-header {
        width: 100%;
        height: auto;
    }
    &-logo {
        margin-top: -9%;
    }
    &-panel {
        position: relative;
        text-align: center;
        padding: 50px 90px 80px;
        z-index: 20;
    }
    &-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 105px;
        width: 100%;
        z-index: 10;
        &::before {
            content: "";
            background: rgba(76, 42, 94, 0.85);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
    &-image {
        width: 100%;
    }
    &-copywrite {
        text-align: right;
        color: #fff;
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0 60px 30px 0;
    }
}

.container {
    &-root {
        width: 100%;
        display: block;
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 1280px) {
    .login-logo-image {
        margin-top: -3%;
    }
}

@media (max-width: 1080px) {
    .login-logo-image {
        margin-top: 0;
    }
}

@media (min-width: 600px) {
    .container-width-sm {
        max-width: 600px;
    }
}
