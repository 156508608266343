.flex {
    display: flex;
}

.flex-jc-center {
    justify-content: center;
}

.flex-ai-center {
    align-items: center;
}

.flex-jc-space-between {
    justify-content: space-between;
}

.flex-column-gap-2 {
    column-gap: 2rem;
}

.flex-column-gap-1-5 {
    column-gap: 1.5rem;
}

.flex-column-gap-1 {
    column-gap: 1rem;
}

// .flex-autosize-ellipsis {
//     flex: 1;
//     min-width: 0;
// }