.top-header {
    position: fixed;
    margin: 0 0 0 280px;
    padding: 30px 36px 0 24px;
    width: calc(100% - 280px);
    background: #f7f7fb;
    z-index: 1;
    & > div {
        display: inline-block;
    }
    &-left {
        float: left;
    }
    &-right {
        float: right;
    }
}
