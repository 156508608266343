@import "./login";
@import "./header";
@import "./body";
@import "./button";
@import "./loading";
@import "./ripple";
@import "./tabs";
@import "./curMaterial";
@import "./component";
@import "./dialog";
@import "./category";
@import "./product";
@import "./tableIcon";

/* =============== REUSABLE CSS CLASSES =============== */
.grid {
    display: grid;
}

.invisible {
    visibility: hidden;
}

.inline-block {
    display: inline-block;
}