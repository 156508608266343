// .btn {
//     display: inline-block;
//     padding: 0 $default-padding-lg;

//     height: $btn-height;
//     min-width: $btn-minwidth;
//     line-height: $btn-height - 2;

//     font-family: $default-fontfamily;
//     font-size: $default-font-size;
//     font-weight: normal;
//     text-align: center;

//     border: 1px solid transparent;
//     border-radius: $btn-border-radius;

//     cursor: pointer;
//     outline: 0;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     margin-right: $btn-margin;

//     &:last-child {
//         margin-right: 0;
//     }

//     &:hover {
//         transition: all 0.3s ease-in-out;
//         opacity: 0.85;
//     }

//     &[disabled],
//     &.disabled {
//         background: $btn-disabled-background-color !important;
//         border-color: transparent !important;
//         color: $btn-disabled-color !important;
//         cursor: not-allowed !important;

//         &:hover {
//             opacity: 1;
//         }
//     }

//     &-shadow {
//         box-shadow: 1px 1px 3px 0px $gray-400;
//     }

//     &#{&}-small {
//         height: $btn-height * 0.8;
//         line-height: $btn-height * 0.8;
//         min-width: $btn-minwidth * 0.6;
//         font-size: $default-font-size * 0.8;
//     }

//     &#{&}-fill {
//         width: 100%;
//     }

//     &#{&}-2x {
//         height: $btn-height * 1.4;
//         line-height: $btn-height * 1.4;
//         min-width: $btn-minwidth * 1.6;
//         font-size: $default-font-size * 1.2;
//         border-radius: 2rem;
//     }

//     &#{&}-primary {
//         background-color: $main-color;
//         color: $default-white-color;
//     }

//     &#{&}-primary-border {
//         background-color: $default-white-color;
//         border-color: $main-color;
//         color: $main-color;

//         &:hover {
//             background-color: $main-light-color;
//             border-color: $main-color;
//             color: $default-white-color;
//         }
//     }

//     &#{&}-secondary {
//         background-color: $sub-color;
//         color: $default-white-color;
//     }

//     &#{&}-secondary-border {
//         background-color: $default-white-color;
//         border-color: $sub-color;
//         color: $sub-color;

//         &:hover {
//             background-color: $sub-light-color;
//             border-color: $sub-color;
//             color: $default-white-color;
//         }
//     }

//     &#{&}-allpage {
//         background-color: $default-white-color;
//         color: $main-color;
//     }

//     &#{&}-cancel {
//         background-color: $gray-200;
//         color: $main-color;
//     }

//     &#{&}-warning {
//         background-color: $danger-color; //$default-pomegranate-color;
//         color: $default-white-color;
//     }

//     &#{&}-other {
//         background-color: $default-white-color;
//         color: $default-silver-color;
//         border-color: $default-silver-color;
//     }

//     &#{&}-input {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         height: 3.0625rem;
//         min-width: 60px;
//         background: $main-color;
//         border-radius: 0 0.625rem 0.625rem 0;
//         font-size: 1.8rem;
//         color: $default-white-color;
//     }
// }

// .btn {
//     &-group {
//         > .btn {
//             background-color: transparent;
//             border-color: $main-light-color;
//             color: $main-light-color;
//             margin-right: 0;

//             &:hover,
//             &.selected {
//                 @extend .btn-primary;
//             }

//             &:not(:last-child) {
//                 border-right-color: transparent;
//                 border-top-right-radius: 0;
//                 border-bottom-right-radius: 0;
//             }

//             &:not(:first-child) {
//                 border-top-left-radius: 0;
//                 border-bottom-left-radius: 0;
//             }
//         }
//     }

//     &-collection & {
//         margin: $default-padding;
//     }

//     &-dropdownlist {
//         position: relative;

//         &-body {
//             display: none;
//             position: absolute;
//             background: $default-white-color;
//             color: $default-black-color;
//             margin-top: 5px;
//             min-width: 130px;
//             border-radius: 10px;
//             border: 1px solid $main-color;

//             &.active {
//                 display: block;
//             }

//             li {
//                 cursor: pointer;
//                 text-align: center;
//                 padding: 5px;

//                 &:first-child {
//                     border-radius: 8px 8px 0 0;
//                 }

//                 &:last-child {
//                     border-radius: 0 0 8px 8px;
//                 }

//                 &:hover {
//                     background-color: $main-color;
//                     color: $default-white-color;
//                 }
//             }
//         }
//     }
// }

// Allen to do
.curves-btn-small {
    &.primary-bg span {
        background: #601986;
    }
    &.secondary-bg span {
        background: #f086a5;
    }

    &.auxiliary-1-bg span {
        background: #65dcfb;
    }

    &.auxiliary-2-bg span {
        background: #bb8df0;
    }
    font-size: 0.75rem;
    span {
        color: #fff;
        display: inline-block;
        text-align: center;
        padding: 4px 12px;
        border-radius: 12px;
    }
}

// 須製作
.curves-btn {
    font-size: 18;
    width: 130;
    height: 50;
    border-radius: 25;
    color: #fff;
    box-shadow: 0px 2px 10px #a7a7a7;
}

.button-group {
    position: relative;
    > button:not(:last-child){
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    > button + button{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 2px solid #eeeeee;
    }
}

.cyanBlue {
    background: #65dcfb;
}

.taroColor {
    background: #bb8df0;
}

.pagination {
    padding: 10px 0 0 0;
    & > ul {
        justify-content: flex-end;
    }
}

.underline {
    text-decoration: underline !important;
}
