.child-X-Gutters-1 {
    & > * + * {
        padding: 6px 0 0 0;
    }
    & *:last-child {
        padding-bottom: 0;
    }
}

.child-Y-Gutters-1 {
    & > * + * {
        padding: 0 0 0 6px;
    }
    & *:last-child {
        padding-right: 0;
    }
}

.child-X-Gutters-2 {
    & > * + * {
        padding: 12px 0 0 0;
    }
    & *:last-child {
        padding-bottom: 0;
    }
}

.child-Y-Gutters-2 {
    & > * + * {
        padding: 0 0 0 12px;
    }
    & *:last-child {
        padding-right: 0;
    }
}

.child-X-Gutters-3 {
    & > * + * {
        padding: 18px 0 0 0;
    }
    & *:last-child {
        padding-bottom: 0;
    }
}

.child-Y-Gutters-3 {
    & > * + * {
        padding: 0 0 0 18px;
    }
    & *:last-child {
        padding-right: 0;
    }
}

.child-X-Gutters-4 {
    & > * + * {
        padding: 24px 0 0 0;
    }
    & > *:last-child {
        padding-bottom: 0;
    }
}

.child-Y-Gutters-4 {
    & > * + * {
        padding: 0 0 0 24px;
    }
    & *:last-child {
        padding-right: 0;
    }
}

.main-inner {
    padding: 21px 27px 30px 24px;
}

.main-outer {
    padding: 0 0 20px 0,
}
