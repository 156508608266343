/* Default Color */

//
// Color Swatches
// $default-turquoise-color: #1abc9c;
// $default-greensea-color: #16a085;
// $default-emerald-color: #2ecc71;
// $default-nephritis-color: #27ae60;
// $default-peter-river-color: #3498db;
// $default-belize-hole-color: #2980b9;
// $default-amethyst-color: #9b59b6;
// $default-wisteria-color: #8e44ad;
// $default-wet-asphalt-color: #34495e;
// $default-midnight-blue-color: #2c3e50;
// $default-sun-flower-color: #ffbe00; //#f1c40f;
// $default-orange-color: #f39c12;
// $default-carrot-color: #e67e22;
// $default-pumpkin-color: #d35400;
// $default-alizarin-color: #e74c3c;
// $default-pomegranate-color: #c0392b;
// $default-clouds-color: #d0d1d2; //#ecf0f1;
// $default-silver-color: #bdc3c7;
// $default-concrete-color: #95a5a6;
// $default-asbestos-color: #7f8c8d;
// $default-vulcan-color: #335053; //#343a40;
// $default-black-color: #000;
// $default-white-color: #fff;
// $default-lightwhite-color: #f6f6f6;

// Color system
// $white: #fff !default;
// $gray-100: #f8f9fa !default;
// $gray-200: #e9ecef !default;
// $gray-300: #dee2e6 !default;
// $gray-400: #ced4da !default;
// $gray-500: #adb5bd !default;
// $gray-600: #6c757d !default;
// $gray-700: #495057 !default;
// $gray-800: #343a40 !default;
// $gray-900: #212529 !default;
// $black: #000 !default;

// $grays: () !default;
// // stylelint-disable-next-line scss/dollar-variable-default
// $grays: map-merge(
//     (
//         '100': $gray-100,
//         '200': $gray-200,
//         '300': $gray-300,
//         '400': $gray-400,
//         '500': $gray-500,
//         '600': $gray-600,
//         '700': $gray-700,
//         '800': $gray-800,
//         '900': $gray-900,
//     ),
//     $grays
// );

// $default-font-size-date: 16px;
// $default-font-size-text: 13px;
// $default-font-size-sm: 12px;

/* Padding Size */
// $default-padding-xs: 4px;
// $default-padding: 6px;
// $default-padding-sm: 10px;
// $default-padding-lg: 14px;
// $default-padding-xlg: 20px;

/* Border */
// $default-border-width: 1px;

/* Border raduis */
// $default-border-radius: 15px;
// $default-shadow: rgba($default-black-color, 0.5);
// $default-shadow-light: rgba($default-black-color, 0.2);
// $default-shadow-dark: rgba($default-black-color, 0.8);
// $default-pageview-space: 2.5rem;

/* Font family */
$default-fontfamily: 'PTSans-Bold', 'Microsoft JhengHei', '微軟正黑體';

/* Colors */
$hue: 14;
$title-color: hsl($hue, 4%, 35%);
$text-color: hsl($hue, 4%, 85%);
$text-color-light: hsl($hue, 4%, 55%);

/* Font Size and typography */
$default-font-size: 16px;
$h1-font-size: 2rem;
$h2-font-size: 1.75rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.25rem;
$h5-font-size: 1rem;
$h6-font-size: 0.85rem;


/*======== Font weight ========*/
$font-medium: 500;
$font-semi-bold: 600;
$font-black: 900;

/*======== Margins bottom ========*/
$mb-0-25: 0.25rem;
$mb-0-5: 0.5rem;
$mb-0-75: 0.75rem;
$mb-1: 1rem;
$mb-1-5: 1.5rem;
$mb-2: 2rem;
$mb-2-5: 2.5rem;

