.main-loader {
    svg path,
    svg rect {
        fill: #601986;
    }
}

.sub-loader {
    svg path,
    svg rect {
        fill: #65dcfb;
    }
}

.white-loader {
    svg path,
    svg rect {
        fill: #fff;
    }
}
