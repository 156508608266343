body {
    font-family: $default-fontfamily;
    font-size: $default-font-size;
    color: #333;
    font-weight: 300;
    // -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    background: #f7f7fb;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/* H1 ~ H6 Style */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
    color: $title-color;
    font-weight: $font-semi-bold;
}

h1,
h2,
h3 {
    margin-top: 0.875rem * 2;
    margin-bottom: 0.875rem;
    line-height: 1.5;
}

h4,
h5,
h6 {
    padding: 0.875rem 0;
    line-height: 1.3;
}

h1 {
    font-size: $h1-font-size;
}

h2 {
    font-size: $h2-font-size;
}

h3 {
    font-size: $h3-font-size;
}

h4 {
    font-size: $h4-font-size;
}

h5 {
    font-size: $h5-font-size;
}

h6 {
    font-size: $h6-font-size;
}

hr {
    border-color: transparent;
    border-bottom: 1px solid #c3dcd1;
    border-radius: 2px;
}

p {
    font-size: $default-font-size;
    line-height: 1.5;
    margin: 0 0 0.875rem;
}

a {
    text-decoration: none;
    color: $main-color;

    &:hover {
        text-decoration: none;
        color: $main-light-color;
    }
}

strong {
    font-weight: 700;
}

input,
textarea,
select
{
    font-family: $default-fontfamily;
    font-weight: 300;
}

button {
    font-family: $default-fontfamily;
    font-weight: 400;
}

label {
    -webkit-tap-highlight-color: transparent;
}

textarea:disabled,
input:disabled {
    background-color: #fff;
    cursor: not-allowed;
}
