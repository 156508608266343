.modal-protal {
    position: relative;
    z-index: 100;
}
.dialog {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, 0%);

    &#{&}-loading {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #{&}-box {
        background: rgb(255, 255, 255);
        border-radius: 0.8rem;
        min-width: 600px;
        max-width: 800px;
        max-height: 1000px;
        overflow: auto;
    }

    #{&}-box-header {
        min-height: 40px;
        padding: 0 1rem;
    }

    #{&}-box-body {
        color: #7f8c8d;
        padding: 0 20px;
    }

    #{&}-box-footer {
        padding: 0 6px;
        min-height: 40px;
        text-align: center;
        button + button {
            margin: 0 0 0 1rem;
        }
    }

    // anmation
    &-box {
        &.info-dialog-enter {
            opacity: 0;
            transform: scale(1.1);
        }

        &.info-dialog-enter-active {
            opacity: 1;
            transform: scale(1);
            transition: opacity 300ms, transform 300ms ease-in;
        }

        &.info-dialog-exit {
            opacity: 1;
            transform: scale(1);
        }

        &.info-dialog-exit-active {
            opacity: 0;
            transform: scale(1.3);
            transition: opacity 250ms, transform 250ms ease-in-out;
        }

        &.warn-dialog-enter {
            opacity: 0;
            transform: scale(0.9);
        }

        &.warn-dialog-enter-active {
            opacity: 1;
            transform: translateX(0);
            transition: opacity 300ms, transform 300ms cubic-bezier(0.35, 1.96, 0.88, 0.41);
        }

        &.warn-dialog-exit {
            opacity: 1;
        }

        &.warn-dialog-exit-active {
            opacity: 0;
            transform: scale(0.9);
            transition: opacity 300ms, transform 300ms;
        }
    }
}
