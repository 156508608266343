.float-left {
    float: left;
}

.float-right {
    float: right;
}

.pos-relative {
    position: relative;
}

.pos-absolute {
    position: absolute;
}

.pos-align-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.hidden {
    display: none !important;
}

.cursor-pointer {
    cursor: pointer;
}

.inline-block {
    display: inline-block;
}

.ban-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.hr {
    width: 100%;
    height: 1px;
    background: #601986;
    margin: 20px 0;
}

.display-none {
    display: none;
}
