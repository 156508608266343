@import "./_defaults";

/* Site Color */
$main-color: #7f49a6;
$main-light-color: #bb8df0;

// $main-dark-color: darken($main-color, 10%);
// $sub-color: #5096cd; //#f6ab19
// $sub-light-color: #75b2d9;
// $sub-dark-color: darken($sub-color, 10%);

// $highlight-color: #d9dd4f;
// $highlight-light-color: #d3d673;
// $highlight-dark-color: #5f7e96;

// $disabled-color: #cbd0d4;
// $danger-color: #ff5a64;
// $warning-color: #ffbe00;
// $warning-light-color: #facd50;

// $main-border-color: #c3dcd1;
// $main-box-color: #f5f9f7;
// $main-font-color: #333;
// $placeholder-color: #757575;

// $dashboard-default-color: #5DC1BE;
// $dashboard-target-color: #D9DD4F;
// $dashboard-female-color: #e656b6;
// $dashboard-male-color: #0285bd;

// $primary: $main-color;
// $secondary: $sub-color;
// $success: $main-color;
// $info: $main-light-color;
// $warning: $warning-color;
// $danger: $danger-color;
// $light: $gray-100;
// $dark: $gray-800;

// $theme-colors: ();
// $theme-colors: map-merge(
//     (
//         "primary": $primary,
//         "secondary": $secondary,
//         "success": $success,
//         "info": $info,
//         "warning": $warning,
//         "danger": $danger,
//         "light": $light,
//         "dark": $dark,
//     ),
//     $theme-colors
// );
// $theme-color-interval: 8%;

/* Header Navbar */
// $header-height: 60px;
// $header-inner-height: $header-height - $default-padding * 2;
// $nav-list-hover-height: 4px;
// $btn-margin: 12px;
// $icon-margin: 4px;
// $stepflow-number-size: 28px;

/* Button Variables */
// $btn-height: 38px;
// $btn-minwidth: 100px;
// $btn-border-radius: 20px;
// $btn-disabled-background-color: rgba(186, 204, 203, 1);
// $btn-disabled-color: rgba($default-white-color, 0.75);
// $btn-shadow: rgba($default-black-color, 0.2);

/* Dialog Variables */
// $dialog-backdrop-color: rgba($default-black-color, 0.5);
// $dialog-background-color: $default-lightwhite-color;
// $dialog-header-background-color: $default-white-color;
// $dialog-padding: $default-padding-xlg;
// $dialog-header-height: $default-padding-xlg * 2;
// $dialog-footer-height: $default-padding-xlg * 2;
// $dialog-border-radius: 10px;

/* Tag Variables */
// $tag-height: 30px;
// $tag-minWidth: 80px;
// $tag-btn-border: 3px;
// $range-thumb-size: 20px;
// $range-border-size: 2px;
// $range-thumb-color: $default-white-color;
// $range-default-color: rgba($default-black-color, 0.2);
// $range-hover-color: rgba($default-black-color, 0.3);
// $range-active-color: rgba($default-black-color, 0.7);
// $box-loading-background-color: rgba($default-white-color, 0.5);

// List group
// $component-active-color: $white;
// $component-active-bg: theme-color("primary");

// $list-group-bg: $white;
// $list-group-border-color: rgba(#000, 0.125);
// $list-group-border-width: $default-border-width;
// $list-group-border-radius: $default-border-radius;

// $list-group-item-padding-y: 0.75rem;
// $list-group-item-padding-x: 1.25rem;

// $list-group-hover-bg: $gray-100;
// $list-group-active-color: $component-active-color;
// $list-group-active-bg: $component-active-bg;
// $list-group-active-border-color: $list-group-active-bg;

// $list-group-disabled-color: $gray-600;
// $list-group-disabled-bg: $list-group-bg;

// $list-group-action-color: $gray-700;
// $list-group-action-hover-color: $list-group-action-color;

// $list-group-action-active-color: $main-color;
// $list-group-action-active-bg: $gray-200;

// $enable-rounded: true;
