.add-icon {
    color: #5096cd;
    width: 2rem !important;
    height: 2rem !important;
}

.edit-icon {
    color: #e2a661;
    width: 2rem !important;
    height: 2rem !important;
}

.delete-icon {
    color: #e65159;
    width: 2rem !important;
    height: 2rem !important;
}
