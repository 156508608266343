.product-list-discount-view {
    align-items: center;
    background-color: #e3e3e3;
    border-radius: 4px;
    color: #4a4a4a;
    display: inline-flex;
    font-size: 0.75rem;
    justify-content: center;
    line-height: 1.618;
    padding-left: 0.618rem;
    padding-right: 0.618rem;
    white-space: nowrap;
}

.product-list-sell-price {
    font-size: 1rem;
}

.product-list-buttons {
    justify-content: flex-end;
    align-content: center;
}
